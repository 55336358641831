<template>
  <st-box v-if="!isDataLoading" class="mt-24">
    <div class="flex mb-16">
      <a
        v-if="showWaitList"
        class="ml-auto flex gap-4 items-center text-md leading-sm cursor-pointer"
        @click="redirectTo(MEMBER_WAIT_LIST_PAGE_URL)"
      >
        <span class="font-medium text-on-surface-elevation-2">{{
          $t('studio.member_manage.join_waitlist_chk_btn')
        }}</span>
        <span class="font-bold text-brand-primary">{{ waitList?.totalElements ?? 0 }}</span>
        <s-icon
          icon="ic-v2-control-arrow-right-line"
          size="xl"
          class="text-on-surface-elevation-1 flex"
        />
      </a>
    </div>
    <st-table
      tableClass="[&>tbody>tr:hover>td:first-of-type]:underline"
      :data="members"
      :columns="columns"
      :showCursorPointer="hasPermission"
      @onRowClick="onClickMember"
    />
    <s-pagination
      v-if="total > 0"
      v-model="currentPage"
      :pageCount="Math.ceil(total / pageSize)"
      displayFirstAndLastButton
      :displayPrevAndNextButton="pageSize > 1"
      class="!py-32"
      @change="onPageChange"
    />

    <div class="flex flex-col justify-center items-center pt-40">
      <template v-if="isFirstLoad">
        <template v-if="isGuideShow">
          <img src="@/assets/images/member/img-member.svg" alt="" />
          <p
            class="mt-[1rem] text-md leading-lg font-medium text-center text-on-surface-elevation-3"
          >
            {{ $t('studio.member_manage.member.guide1') }}<br />
            {{ $t('studio.member_manage.member.guide2') }}<br />
            {{ $t('studio.member_manage.member.guide3') }}<br />
          </p>
        </template>
      </template>

      <template v-else>
        <p
          v-if="props.isSearching && members.length === 0"
          class="mt-[1rem] text-md leading-lg font-medium text-center text-on-surface-elevation-3"
        >
          {{ $t('studio.member_manage.search_nmrf') }}
        </p>
      </template>
    </div>
  </st-box>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';

import { fetchWaitListApi } from '@/apis/member.api';
import StBox from '@/components/common/st-box.vue';
import StTable from '@/components/common/table/st-table.vue';
import { STATUS_CODE } from '@/constants/error.const';
import { DEFAULT } from '@/constants/member-role.const';
import { MEMBER_MNG_PAGE_URL, MEMBER_WAIT_LIST_PAGE_URL } from '@/constants/url.const';
import { useRoleStore } from '@/stores/role.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { Pagination } from '@/types/common/pagination.type';
import type { MemberModel } from '@/types/member/member-model.type';
import type { WaitListResponse } from '@/types/member/member-response.type';
import type { RoleType } from '@/types/role/role.request.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';

const props = withDefaults(
  defineProps<{
    members: MemberModel[];
    total: number;
    pageSize: number;
    isSearching: boolean;
    isFirstLoad: boolean;
    hasPermission: boolean;
    currentPage: number;
  }>(),
  {
    currentPage: 1
  }
);

const emits = defineEmits<{
  memberPageChange: [v: number];
  sort: [sortValue: number, sortKey: string];
}>();

const route = useRoute();

const userStore = useUserStore();
const roleStore = useRoleStore();

const { selectedGroupId } = storeToRefs(userStore);
const { roleList } = storeToRefs(roleStore);
const { fetchRoleList } = roleStore;

const { members, hasPermission, currentPage } = toRefs(props);

const showWaitList = ref<boolean>(true);
const isDataLoading = ref<boolean>(true);

const groupId = route.params.groupId as string;

const onSortNickname = (sortValue: number, sortKey: string) => {
  emits('sort', sortValue, sortKey);
};

const onSortInvolvedProjectCount = (sortValue: number, sortKey: string) => {
  emits('sort', sortValue, sortKey);
};

const onSortJoinedAt = (sortValue: number, sortKey: string) => {
  emits('sort', sortValue, sortKey);
};

const columns = ref<Column[]>([
  {
    title: 'studio.member_manage.member.member_title.nick_header',
    dataIndex: 'nickname',
    sortKey: 'nickname',
    align: 'left',
    fontWeight: 'bold',
    width: 'auto',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortNickname(sortValue, sortKey)
  },
  {
    title: 'studio.member_manage.member.member_title.project_count_header',
    dataIndex: 'involvedProjectCount',
    sortKey: 'involvedProjectCount',
    align: 'left',
    fontWeight: 'normal',
    width: '16rem',
    render: (record: MemberModel) => {
      return (record.involvedProjectCount ? record.involvedProjectCount > 0 : false)
        ? record.involvedProjectCount.toString()
        : '-';
    },
    onSort: ({ sortValue, sortKey }: ColumnSorting) =>
      onSortInvolvedProjectCount(sortValue, sortKey)
  },
  {
    title: 'studio.member_manage.member.member_title.regi_date_header',
    dataIndex: 'joinedAt',
    sortKey: 'joinedAt',
    align: 'left',
    fontWeight: 'normal',
    width: '16rem',
    render: (record: MemberModel) => getDateTimeByLocale(record.joinedAt ?? record.createdAt),
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortJoinedAt(sortValue, sortKey)
  }
]);

const waitList = ref<Pagination<WaitListResponse[]>>();

const isNewRoleCreated = computed(() =>
  roleList.value.contents.some((role: RoleType) => role.groupId !== DEFAULT)
);

const isRoleModified = computed(() =>
  roleList.value.contents.some((role: RoleType) => role.groupId !== DEFAULT && role.modifiedAt)
);

const isGuideShow = computed(
  () => members.value.length <= 1 && !isNewRoleCreated.value && !isRoleModified.value
);

const onClickMember = async (member?: MemberModel) => {
  if (!member?.groupMemberNo || !hasPermission.value) {
    return;
  }
  redirectTo(`${MEMBER_MNG_PAGE_URL}/${member?.groupMemberNo}`);
};

const onPageChange = (page: number) => {
  emits('memberPageChange', page);
};

const init = async () => {
  try {
    waitList.value = await fetchWaitListApi(groupId);

    showWaitList.value = hasPermission.value;
  } catch (error) {
    const err: ErrorResponse = error as ErrorResponse;
    if (err.statusCode === STATUS_CODE.READ_WRITE_ACCESS_DENIED) {
      showWaitList.value = false;
    }
  }
  await fetchRoleList(selectedGroupId.value);

  isDataLoading.value = false;
};

init();
</script>
