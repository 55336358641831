<template>
  <st-box class="mt-20">
    <div class="flex gap-8 items-center mb-8">
      <s-button variant="outline" size="xs" @click="onFoldingToggle(false)">
        {{ $t('studio.member_manage.project_list_collapse_all_btn') }}
      </s-button>
      <s-button variant="outline" size="xs" @click="onFoldingToggle(true)">
        {{ $t('studio.member_manage.project_list_expand_all_btn') }}
      </s-button>
    </div>
    <m-table>
      <colgroup>
        <col style="width: auto" />
        <col style="width: 20rem" />
        <col style="width: 15.4rem" />
      </colgroup>
      <thead>
        <tr>
          <st-th
            v-for="(column, colIndex) in columns"
            :key="column.title"
            sortingUse
            sortingDefault
            :thTitle="$t(`${column.title}`)"
            :sortValue="sortList[colIndex]"
            thClass="bg-[#F7FAFD]"
            @onSort="
              (sortValue) => {
                for (let i = 0; i < sortList.length; i++) {
                  if (i !== colIndex) {
                    sortList[i] = SORT_VALUE.NONE;
                  }
                }
                sortList[colIndex] = sortValue;
                onSort(sortValue, column.dataIndex);
              }
            "
          />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-if="projects && projects.length > 0" colspan="3">
            <div class="flex flex-col gap-8 mt-8">
              <st-folding
                v-for="(project, indexProject) in projects"
                :key="`${project.projectId}-${project.expand}`"
                v-model:isOpenDetail="project.expand"
                projectType
                :name="project.projectName"
                :creationDate="getDateTimeByLocale(project.createdAt)"
                :memberCount="(project.projectMemberCount ?? 0).toString()"
                @onFold="onExpand(project, indexProject, $event)"
              >
                <div
                  v-if="project.products && project.products.length > 0"
                  class="mt-16 mx-24 mb-20"
                >
                  <p class="mb-4 flex items-center h-[3rem] font-bold text-xl gap-8 leading-sm">
                    <span class="text-on-surface-elevation-2">{{
                      $t('studio.member_manage.project_list_member_title')
                    }}</span>
                    <span class="text-brand-primary">{{ project.productCount }}</span>
                  </p>
                  <project-member
                    :memberProducts="project.products"
                    :hasPermission="hasPermission"
                    @sort="fetchMember(project, $event)"
                  />
                </div>
                <div
                  v-else-if="!loadingProducts"
                  colspan="3"
                  class="text-sm text-on-surface-elevation-2 leading-md align-middle first:pl-20 last:pr-20 px-12 py-16 text-center"
                >
                  {{ $t('studio.member_manage.list_no_data') }}
                </div>
              </st-folding>
            </div>
          </td>
          <st-td v-else-if="!loading" colspan="3" aCenter class="h-[20rem]">
            {{
              isSearching
                ? $t('studio.member_manage.search_nmrf')
                : $t('studio.member_manage.list_no_data')
            }}
          </st-td>
        </tr>
      </tbody>
    </m-table>
    <s-pagination
      v-if="total > pageSize"
      v-model="currentPage"
      :pageCount="total / pageSize"
      displayFirstAndLastButton
      class="!py-32"
      @change="onPageChange"
    />
  </st-box>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, toRefs } from 'vue';

import { fetchMemberDetailsByProjectApi } from '@/apis/member.api';
import StBox from '@/components/common/st-box.vue';
import StFolding from '@/components/common/st-folding.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import ProjectMember from '@/components/member-mng/project-member.vue';
import { SORT_VALUE } from '@/constants/common.const';
import { useMemberStore } from '@/stores/member.store';
import { useUserStore } from '@/stores/user.store';
import type { ProjectsByTeamId } from '@/types/member/member-model.type';
import type { Column } from '@/types/table.type';
import { sortValueToDirection } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';

const props = defineProps<{
  total: number;
  pageSize: number;
  loading: boolean;
  projects: ProjectsByTeamId[];
  isSearching: boolean;
  hasPermission: boolean;
}>();

const loadingProducts = ref<boolean>(false);

const emits = defineEmits<{
  projectsPageChange: [v: number];
  sort: [sortValue: number, sortKey?: string];
}>();

const userStore = useUserStore();
const memberStore = useMemberStore();

const { projects } = toRefs(props);

const { selectedGroupId } = storeToRefs(userStore);

const { setProjectList } = memberStore;

const columns: Column[] = [
  { title: 'studio.member_manage.project_project_name_header1', dataIndex: 'PROJECT_NAME' },
  { title: 'studio.member_manage.project_no_of_member_header2', dataIndex: 'MEMBER_COUNT' },
  { title: 'studio.member_manage.project_project_name_header2', dataIndex: 'CREATED_AT' }
];

const sortList = columns.map(() => SORT_VALUE.NONE);

const currentPage = ref<number>(1);

const onExpand = async (project: ProjectsByTeamId, index: number, value: boolean) => {
  if (!value) {
    return;
  }

  projects.value[index].expand = value;
  setProjectList(projects.value);

  loadingProducts.value = true;

  const res = await fetchMember(project, {});
  loadingProducts.value = false;

  if (res) {
    projects.value[index].products = res.products;
    projects.value[index].productCount = res.projectMemberCount;
  }
};

const fetchMember = async (
  project: ProjectsByTeamId,
  options: {
    sortKey?: string;
    sortValue?: number;
  }
) => {
  const { sortKey, sortValue } = options;
  const direction = sortValueToDirection(sortValue);

  const res = await fetchMemberDetailsByProjectApi({
    groupId: selectedGroupId.value,
    projectId: project.projectId,
    sortKey,
    direction
  });

  if (res && res.products.length > 0) {
    const index = projects.value.findIndex(
      (p: ProjectsByTeamId) => p.projectId === project.projectId
    );

    projects.value[index].products = res.products;
    projects.value[index].productCount = res.projectMemberCount;
  }

  return res;
};

const onPageChange = (page: number) => {
  emits('projectsPageChange', page);
};

const onFoldingToggle = (value: boolean) => {
  projects.value.forEach((product: ProjectsByTeamId, index: number) => {
    product.expand = value;
    onExpand(product, index, value);
  });
};

const onSort = (sortValue: number, sortKey?: string) => {
  emits('sort', sortValue, sortKey);
};
</script>
