<template>
  <st-table
    :data="memberProducts"
    :columns="columns"
    tableClass="[&>tbody>tr:hover>td:first-of-type]:underline"
    :showCursorPointer="hasPermission"
    @onRowClick="onClickMember"
  >
    <template #col-2="{ row: p }">
      <st-td aLeft textClass="text-md" :tdValue="getMemberRoleName(p.memberRoleName)">
        <button
          type="button"
          class="h-16 align-middle ml-4 relative top-[-.1rem]"
          @click.stop="openRolePermissionDialog(p.memberRoleNo)"
        >
          <s-icon
            size="xl"
            icon="ic-v2-state-help-circle-line"
            class="text-on-surface-elevation-4 flex"
          />
        </button>
      </st-td>
    </template>
  </st-table>
</template>

<script setup lang="ts">
import { ref, shallowRef, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import RolePermissionDialog from '@/components/my-info/role-permission-dialog.vue';
import { showDialog } from '@/composables/useDialog';
import {
  ROLE_DEVELOPER,
  ROLE_MARKETER,
  ROLE_OPERATOR,
  ROLE_OWNER,
  ROLE_PROJECT_MANAGER
} from '@/constants/permission.const';
import { MEMBER_MNG_PAGE_URL } from '@/constants/url.const';
import type { ProductMemberModel, ProductMemberResponse } from '@/types/member/member-model.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';

const props = defineProps<{
  memberProducts: ProductMemberResponse[];
  hasPermission: boolean;
}>();

const emits = defineEmits<{
  sort: [v: { sortValue: number; sortKey: string }];
}>();

const { t } = useI18n();

const { memberProducts, hasPermission } = toRefs(props);

const columns = ref<Column[]>([
  {
    title: 'studio.member_manage.project_list_nickname_header3',
    dataIndex: 'nickname',
    width: 'auto',
    align: 'left',
    sortKey: 'nickname',
    thClass: 'bg-[#F7FAFD]',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => emits('sort', { sortValue, sortKey })
  },
  {
    title: 'studio.member_manage.project_list_product_name_header1',
    dataIndex: 'productName',
    width: '23.3rem',
    align: 'left',
    sortKey: 'productName',
    thClass: 'bg-[#F7FAFD]',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => emits('sort', { sortValue, sortKey })
  },
  {
    title: 'studio.member_manage.project_list_role_header4',
    dataIndex: 'memberRoleName',
    width: '23.3rem',
    sortKey: 'roleName',
    thClass: 'bg-[#F7FAFD]',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => emits('sort', { sortValue, sortKey })
  },
  {
    title: 'studio.member_manage.project_list_date_authorized_header5',
    dataIndex: 'roleProvidedAt',
    width: '15.4rem',
    align: 'left',
    tooltipUse: true,
    titleTooltip: t('studio.member_manage.project_list_date_authorized_header5'),
    fontWeight: 'bold',
    sortKey: 'createdAt',
    thClass: 'bg-[#F7FAFD]',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => emits('sort', { sortValue, sortKey }),
    render: (row: ProductMemberModel) => getDateTimeByLocale(row.roleProvidedAt)
  }
]);

const getMemberRoleName = (roleName?: string) => {
  if (!roleName) {
    return '';
  }

  switch (roleName) {
    case ROLE_OWNER:
      return t('studio.gnb.group_myinfo.my_group_role_type_owner');
    case ROLE_DEVELOPER:
      return t('studio.member_manage.role_value_developer');
    case ROLE_MARKETER:
      return t('studio.member_manage.role_value_marketer');
    case ROLE_OPERATOR:
      return t('studio.member_manage.role_value_operator');
    case ROLE_PROJECT_MANAGER:
      return t('studio.member_manage.role_value_manager');
    default:
      return roleName;
  }
};

const openRolePermissionDialog = async (roleNo: number) => {
  await showDialog({
    component: shallowRef(RolePermissionDialog),
    props: {
      header: 'Role Permission',
      roleNo
    },
    severity: 'info'
  });
};

const onClickMember = (member?: ProductMemberResponse) => {
  if (!member?.groupMemberNo || !hasPermission.value) {
    return;
  }
  redirectTo(`${MEMBER_MNG_PAGE_URL}/${member?.groupMemberNo}`);
};
</script>
