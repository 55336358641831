<template>
  <div class="w-full">
    <st-title :title="$t('studio.lnb.common_2depth_member_management')">
      <s-button
        variant="secondary"
        size="md"
        class="min-w-[11.6rem]"
        @click="redirectTo(MEMBER_ROLES_PAGE_URL)"
      >
        {{ $t('studio.member_manage.role_manage_btn') }}
      </s-button>
      <s-button
        variant="primary"
        size="md"
        class="min-w-[11.6rem]"
        @click="redirectTo(MEMBER_INVITE_PAGE_URL)"
      >
        {{ $t('studio.member_manage.member_invite_btn') }}
      </s-button>
    </st-title>

    <div class="mt-40">
      <s-tabs
        v-model="tab"
        variant="line-flexible"
        size="sm"
        :defaultIndex="0"
        defaultValue="member-tab"
        class="mt-40 stove-studio-tab"
        @update:modelValue="onChangeTab($event)"
      >
        <div class="flex gap-28 items-center">
          <s-tab-list class="gap-16">
            <s-tab-item value="member-tab">
              {{ $t('studio.member_manage.member_all_title') }}
            </s-tab-item>
            <s-tab-item value="project-tab">
              {{ $t('studio.member_manage.mem_by_project_title') }}
            </s-tab-item>
          </s-tab-list>
          <div class="ml-auto shrink-0 flex gap-28">
            <a
              v-if="isMenuWritable"
              class="flex items-center gap-4 text-on-surface-elevation-2 text-md font-medium leading-sm cursor-pointer"
              @click="redirectTo(MEMBER_INVITE_HISTORY_PAGE_URL)"
            >
              <s-icon icon="ic-v2-community-board-all-line" size="3xl" class="flex" />
              {{ $t('studio.member_manage.history') }}
            </a>
            <div class="w-[22rem]">
              <input-text
                name="keyword"
                size="lg"
                variant="outline"
                searchable
                :placeholder="$t('studio.member_manage.search_place_holder')"
                :rules="{
                  max_length: {
                    max: 100,
                    message: $t('studio.common.def_key.exceed', { length: 100 })
                  }
                }"
                maxLength="100"
                :countable="false"
                @search="onSearch"
                @clear="onSearch"
              />
            </div>
          </div>
        </div>

        <s-tab-panels class="mt-16">
          <s-tab-panel :value="TAB.MEMBERS">
            <members-tab
              :members="memberListResponse?.contents ?? []"
              :total="memberListResponse?.totalElements ?? 0"
              :pageSize="memberListResponse?.size ?? 50"
              :isSearching="isSearching"
              :isFirstLoad="isFirstLoad"
              :hasPermission="isMenuWritable"
              :currentPage="memberCurrentPage"
              @memberPageChange="fetchMembers({ page: $event })"
              @sort="(sortValue, sortKey) => fetchMembers({ sortKey, sortValue })"
            />
          </s-tab-panel>
          <s-tab-panel :value="TAB.PROJECTS">
            <projects-tab
              :projects="projects ?? []"
              :total="totalProjectsByTeamId?.totalElements ?? 0"
              :pageSize="totalProjectsByTeamId?.size ?? 50"
              :loading="loadingProject"
              :isSearching="isSearching"
              :hasPermission="isMenuWritable"
              @projectsPageChange="onProjectPageChange"
              @sort="(sortValue, sortKey) => fetchProjectShowList({ sortKey, sortValue })"
            />
          </s-tab-panel>
        </s-tab-panels>
      </s-tabs>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { ref } from 'vue';

import { definePageMeta } from '@/.nuxt/imports';
import {
  fetchMemberDetailsByProjectApi,
  fetchMemberListApi,
  fetchProjectsByTeamIdApi
} from '@/apis/member.api';
import StTitle from '@/components/common/st-title.vue';
import MembersTab from '@/components/member-mng/members-tab.vue';
import ProjectsTab from '@/components/member-mng/projects-tab.vue';
import InputText from '@/components/validation/input-text.vue';
import {
  MEMBER_INVITE_HISTORY_PAGE_URL,
  MEMBER_INVITE_PAGE_URL,
  MEMBER_ROLES_PAGE_URL
} from '@/constants/url.const';
import { useAppStore } from '@/stores/app.store';
import { useMemberStore } from '@/stores/member.store';
import { useUserStore } from '@/stores/user.store';
import type { Pagination } from '@/types/common/pagination.type';
import type { MemberListModel, ProjectsByTeamId } from '@/types/member/member-model.type';
import { redirectTo, sortValueToDirection } from '@/utils/common.util';

definePageMeta({
  title: '멤버관리'
});

const userStore = useUserStore();
const memberStore = useMemberStore();

const loadingProject = ref<boolean>(false);

const { selectedGroupId } = storeToRefs(userStore);
const { projectList } = storeToRefs(memberStore);

const { setProjectList } = memberStore;

const TAB = {
  MEMBERS: 'member-tab',
  PROJECTS: 'project-tab'
};

const { values, isFieldValid, setValues } = useForm({
  initialValues: {
    keyword: ''
  }
});

const tab = ref<string>(TAB.MEMBERS);

const memberListResponse = ref<MemberListModel>();
const projects = ref<ProjectsByTeamId[]>();
const totalProjectsByTeamId = ref<Pagination<ProjectsByTeamId[]>>();

const isSearching = ref<boolean>(false);
const isFirstLoad = ref<boolean>(true);
const { isMenuWritable } = storeToRefs(useAppStore());

const sortMemberKey = ref<string | undefined>();
const sortMemberDirection = ref<string | undefined>();

const sortProjectKey = ref<string | undefined>();
const sortProjectDirection = ref<string | undefined>();

const memberCurrentPage = ref<number>(1);

const fetchMembers = async (options: {
  page?: number;
  keyword?: string;
  sortKey?: string;
  sortValue?: number;
}) => {
  const { page, keyword, sortKey, sortValue } = options;

  if (!page) {
    memberCurrentPage.value = 1;
  } else {
    memberCurrentPage.value = page;
  }

  const direction = sortValueToDirection(sortValue) ?? sortMemberDirection.value;
  const key = sortKey === 'involvedProjectCount' ? 'projectCount' : sortKey ?? sortMemberKey.value;

  if (sortKey) {
    sortMemberDirection.value = direction;
    sortMemberKey.value = key;
  }

  const result = await fetchMemberListApi(selectedGroupId.value, page, {
    keyword,
    sortKey: sortMemberKey.value,
    direction: sortMemberDirection.value
  });

  memberListResponse.value = cloneDeep(result);
};

const fetchProjectShowList = async (options: {
  page?: number;
  sortKey?: string;
  sortValue?: number;
  q?: string;
}) => {
  const { page, sortKey, sortValue, q } = options;

  const direction = sortValueToDirection(sortValue) ?? sortProjectDirection.value;
  const key = sortKey ?? sortProjectKey.value;

  if (sortKey) {
    sortProjectDirection.value = direction;
    sortProjectKey.value = key;
  }

  loadingProject.value = true;

  totalProjectsByTeamId.value = await fetchProjectsByTeamIdApi(selectedGroupId.value, page, {
    sortKey: sortProjectKey.value,
    direction: sortProjectDirection.value,
    q
  });
  loadingProject.value = false;
  if (!totalProjectsByTeamId.value) {
    return;
  }

  projects.value = totalProjectsByTeamId.value.contents;
  projects.value.forEach(async (project: ProjectsByTeamId) => {
    const p = projectList.value.find((p: ProjectsByTeamId) => p.projectId === project.projectId);
    if (p && p.expand) {
      const res = await fetchMemberInProjects(project);
      if (res) {
        project.products = res.products;
        project.productCount = res.projectMemberCount;
        project.expand = p.expand;
      }
    }
  });

  setProjectList(projects.value);
};

const fetchMemberInProjects = async (project: ProjectsByTeamId) => {
  const res = await fetchMemberDetailsByProjectApi({
    groupId: selectedGroupId.value,
    projectId: project.projectId
  });

  return res;
};

const onChangeTab = async (tab: string) => {
  setValues({ keyword: '' });
  isFirstLoad.value = true;
  isSearching.value = false;

  if (tab === TAB.MEMBERS) {
    await fetchMembers({ page: 1 });

    return;
  }

  projects.value = [];
  await fetchProjectShowList({ page: 1 });
};

const onProjectPageChange = async (page?: number) => {
  await fetchProjectShowList({ page });
};

const onSearch = async () => {
  if (values.keyword.length > 100) {
    return;
  }

  if (isFieldValid('keyword')) {
    if (tab.value === TAB.MEMBERS) {
      await fetchMembers({ keyword: values.keyword });
      if (values.keyword === '') {
        isFirstLoad.value = true;
        isSearching.value = false;

        return;
      }
    }

    if (tab.value === TAB.PROJECTS) {
      await fetchProjectShowList({ q: values.keyword });
    }

    isSearching.value = true;
    isFirstLoad.value = false;
    return;
  }

  isSearching.value = false;
};

const init = async () => {
  fetchMembers({ page: 1 });
};

init();
</script>
